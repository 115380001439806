let wheelEvt = "onwheel" in document.createElement("div") ? "wheel" : 
          			document.onmousewheel !== undefined ? "mousewheel" : 
          			"DOMMouseScroll";

const col_resize = () => {
	if (document.querySelector('.columns')){
		const cols = document.querySelector('.columns');
		cols.style.height = cols.querySelector('.columns__inner').offsetHeight + window.innerHeight / 50 + 'px';
	}
	if (document.querySelector('section').scrollHeight > document.querySelector('section').offsetHeight){
		document.querySelector('section').style.height = document.querySelector('section').scrollHeight+'px';
	}else{
		document.querySelector('section').removeAttribute('style')
	}
}

const landing = () => {
	if (
		(document.querySelector('section').offsetHeight <= window.innerHeight) && 
		document.body.classList.contains('home')
	){
		document.documentElement.classList.add('fixed');
		if (document.querySelector('.landing')){

			document.querySelector('.landing').addEventListener('click', () =>{
				document.body.classList.add('active');
			});
			
			document.documentElement.addEventListener(wheelEvt, scroll_func);

			if (window.location.hash == '#home'){
				document.body.classList.add('active');
			}
		}

		
	}
};

const scroll_func = (e) => {

	let style = window.getComputedStyle(document.body);
	
	if (e.deltaY > 0 && parseInt(style.top) == 0){
		document.body.classList.add('active');
		
		document.querySelector('.home').classList.remove('disabled');
		setTimeout(function(){
			document.documentElement.classList.add('active');
		},500);
	}
	
};


document.addEventListener('DOMContentLoaded', () =>{
	// col_resize();
	landing();
});
window.addEventListener('resize', () => {
	// col_resize();
	landing();
});