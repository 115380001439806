
if (document.getElementsByClassName('accordion').length){
	let stopVideo = (item) => {
		console.log('stop');
		[].forEach.call(item.querySelectorAll('iframe'), (i) => {
			var leg= i.getAttribute("src");
	    	i.setAttribute("src",leg);	
		    
		});
	}
	let acc = document.getElementsByClassName('accordion')[0];
	[].forEach.call(acc.getElementsByClassName('accordion__item'), (item) => {
		item.getElementsByClassName('accordion__title')[0].addEventListener('click', () => {
			
			if (item.classList.contains('accordion__item--active')){
				item.classList.remove('accordion__item--active');
				stopVideo(item);
				return;
			}

			[].forEach.call(acc.getElementsByClassName('accordion__item--active'), (active) => {
				stopVideo(active);
				active.classList.remove('accordion__item--active');

			});
			item.classList.add('accordion__item--active');


	

		})
	});
}
