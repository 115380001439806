// Projects link

const scrollLinks = document.getElementsByClassName('scroll');


[].forEach.call(scrollLinks, (link) => {

	link.addEventListener('click', (e) => {
		e.preventDefault();
        let href = link.getAttribute('href');
		document.querySelector(href).scrollIntoView({
			behavior: 'smooth' 
		})
	});

});

// Carousels

const carousels = document.getElementsByClassName('carousel');


[].forEach.call(carousels, (carousel) => {

	let items = carousel.getElementsByClassName('carousel__item');
	let index = carousel.getElementsByClassName('carousel__nav__index')[0];
	let count = carousel.getElementsByClassName('carousel__nav__count')[0];
	let next = carousel.getElementsByClassName('next')[0];
	let prev = carousel.getElementsByClassName('previous')[0];
	let active = carousel.getElementsByClassName('carousel__item--active')[0];
	let navWrap = carousel.getElementsByClassName('carousel__nav')[0];

	let i = 0;
	let c = 0;
	let maxHeight = 0;

	index.textContent = 1;
	count.textContent = items.length;

	if (items.length == 1){
		navWrap.classList.add('hide')
	}
	

	next.addEventListener('click', () => {
		stopVideo();

		i++;
		if (!active.nextElementSibling){
			i = 0;
		}

		active.classList.remove('carousel__item--active');
		
		active = items[i];

		active.classList.add('carousel__item--active');

		index.textContent = i + 1;
		

	});

	prev.addEventListener('click', () => {
		stopVideo();
		i--;
		if (!active.previousElementSibling){
			i = items.length - 1;
		}
		console.log(i);

		active.classList.remove('carousel__item--active');
		
		active = items[i];

		active.classList.add('carousel__item--active');

		index.textContent = i + 1;
		

	});
	let stopVideo = () => {
		[].forEach.call(active.querySelectorAll('iframe'), (i) => {
			var leg= i.getAttribute("src");
		    // i.setAttribute("src",'');
		    // setTimeout(function(){
		    	i.setAttribute("src",leg);	
		    // },10)
		    
		});
	}
	
});

// Scrolling fixed header

if (document.body.classList.contains('page-template-services')){
	let anchor = document.getElementsByClassName('anchor')[0];
	let header = document.getElementsByTagName('header')[0];
	let bar = document.getElementsByClassName('bar')[0];
	let link = header.querySelector('a');
	let serviceScroll = () =>{
		// let rect = bar.getBoundingClientRect();
		if (window.pageYOffset >= (anchor.offsetTop)){
			header.classList.add('scroll');
			header.style.top = anchor.offsetTop + 'px';
		}else{
			header.classList.remove('scroll');
			header.style.top = '0';
		}

		// Range 0 - (window.innerHeight/2);

		let max = window.innerHeight/2

		let perc = (max - window.pageYOffset) / max;
		perc = (perc < 0)?0:perc;
		// console.log(perc);
		bar.querySelector('p').style.opacity = perc;
	};

	document.addEventListener('scroll', serviceScroll);

}
