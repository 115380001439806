// import moment from 'moment';

const timer = document.querySelector('.timer');
const timer_el = timer.querySelector('.timer__inner');
const timer_output = () => {
  let date = document.createElement('span');
  timer_el.appendChild(date);
  let time = document.createElement('span');
  timer_el.appendChild(time);
  setInterval(() => {
    date.textContent = moment().format("MMM Do YYYY");
    time.textContent = moment().format('H:mm:ss');
  },250);
}
timer_output();