let height = "innerHeight" in window 
		       ? window.innerHeight
		       : document.documentElement.offsetHeight; 
[].forEach.call(document.getElementsByClassName('video'), (v) => {
	console.log('video rezise')
	let sizer = () => {

		height = "innerHeight" in window 
		       ? window.innerHeight
		       : document.documentElement.offsetHeight; 

		let inner = v.querySelector('div');
		let rec = inner.getBoundingClientRect();

		if (height >= 640){
			inner.style.paddingLeft = rec.height * 1.777 - 32 + 'px'
		}else{
			inner.removeAttribute('style');
		}

	}
	sizer();
	window.addEventListener('resize', sizer);
})