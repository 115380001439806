if (document.getElementsByClassName('credit').length){
	var supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
	var credit = document.getElementsByClassName('credit')[0];
	
	if (supportsTouch){
		credit.addEventListener('click', function(e){
			if (e.target.tagName.toLowerCase == 'a'){
				return;
			}
			credit.classList.add('hover');
			setTimeout(function(){
				credit.classList.add('click');	
			},500);
			
		});
	}else{
		credit.addEventListener('mouseover', function(){
			credit.classList.add('hover');
			credit.classList.add('click');
		})
		credit.addEventListener('mouseout', function(){
			credit.classList.remove('hover');
			credit.classList.remove('click');
		});
	}
}